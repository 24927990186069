<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        lg="6"
      >
        <base-material-card
          color="error"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Documentos pendientes
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Listado de documentos pendientes por aportar a los expedientes
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="pendientes"
              :options="options"
              height="300px"
            />
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        lg="6"
      >
        <base-material-card
          color="info"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Expedientes abiertos
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Listado de expedientes actualmente en proceso
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers2"
              :items="abiertos"
              :options="options"
              height="300px"
            />
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-file-cabinet"
          title="Expedientes firmados"
          value="2"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-calendar-multiselect"
          title="Citas confirmadas"
          value="1"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ExpedientesApi from '@/services/api/Expedientes'

  export default {
    name: 'ClienteDashboard',

    data () {
      return {
        options: {
          itemsPerPage: 5,
        },
        headers: [
          {
            sortable: false,
            text: 'No. Expediente',
            value: 'expediente',
          },
          {
            sortable: false,
            text: 'Procedimiento',
            value: 'procedimiento',
          },
          {
            sortable: false,
            text: 'Tipo de documento',
            value: 'tipo',
          },
        ],
        pendientes: [],
        headers2: [
          {
            sortable: false,
            text: 'No. Expediente',
            value: 'id',
          },
          {
            sortable: false,
            text: 'Procedimiento',
            value: 'procedimiento.nombre',
          },
          {
            sortable: false,
            text: 'Estado',
            value: 'estado.nombre',
          },
        ],
        abiertos: [],
      }
    },

    created () {
      this.fetchPendientes()
      this.fetchExpedientesAbiertos()
    },

    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
      fetchPendientes () {
        ExpedientesApi.getPendientes()
          .then(pendientes => {
            this.pendientes = pendientes.pendientes
          })
          .catch(error => console.log(error))
      },
      fetchExpedientesAbiertos () {
        ExpedientesApi.getAllExpedientesUsuarioAbiertos()
          .then(abiertos => {
            this.abiertos = abiertos
          })
          .catch(error => console.log(error))
      },
    },
  }
</script>
